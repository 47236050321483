<template>
  <ModuleForm
    :module="source"
    :show-header-info="disabled"
  >
    <template #form-title>
      {{ $t(`route.${$route.name}`) }}
    </template>
    <template #header-buttons>
      <ModuleFormButton
        v-if="disabled"
        :required-permissions="requiredPermissions.editButton"
        icon="edit"
        @click="goToEdit"
      />
      <ModuleFormButton
        v-else
        :title="$t('buttons.save')"
        :required-permissions="requiredPermissions.saveButton"
        :disabled="callingAPI"
        @click="save"
      />
      <ModuleFormDeleteButton
        v-if="source.id"
        :record-id="source.id"
        :required-permissions="requiredPermissions.deleteButton"
        :disabled="callingAPI"
        store-name="source"
        list-router-path="source"
      />
      <ModuleFormButton
        icon="close"
        @click="$router.push('/source')"
      />
    </template>
    <template #form-content>
      <Preloader v-if="callingAPI" on-top />
      <div class="form-content">
        <div class="form-content__left">
          <Input
            v-model="source.name"
            @blur="$v.source.name.$touch()"
            :error="$v.source.name.$error"
            id="source_name"
            :label="$t('sources.name')"
            :disabled="disabled"
          />
          <Input
            v-model="source.customText"
            id="source_customText"
            :label="$t('sources.customText')"
            :disabled="disabled"
          />
        </div>
      </div>
    </template>
  </ModuleForm>
</template>

<script>
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import NotifyService from '@/services/NotifyService'
import PermissionService from '@/services/PermissionService'
import ModuleFormDeleteButton from '@/components/shared/ModuleFormDeleteButton'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import ModuleForm from '@/components/shared/ModuleForm'
import Input from '@/components/form/inputs/Input'
import Preloader from '@/components/preloader/Preloader'

export default {
  name: 'SourceNewView',
  props: {
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      source: { name: '', customText: '' },
      requiredPermissions: PermissionService.REQUIRED_PERMISSIONS.SYSTEM_SOURCE_PERMISSIONS
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ])
  },
  components: {
    Preloader,
    ModuleFormDeleteButton,
    ModuleFormButton,
    Input,
    ModuleForm
  },
  validations: {
    source: {
      name: {
        required
      }
    }
  },
  methods: {
    getSource () {
      this.$store.dispatch('source/fetchOne', this.$route.params.id)
        .then(source => {
          this.source = source
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
      } else {
        const action = this.source.id ? 'update' : 'create'
        const successNotify = this.source.id ? 'record_was_updated' : 'record_was_created'
        this.$store.dispatch(`source/${action}`, this.source)
          .then(detail => {
            const error = this.$store.getters['source/error']
            if (!error) {
              this.source = detail
              NotifyService.setSuccessMessage(this.$t(`notify.${successNotify}`))
              this.goToEdit()
            }
          })
      }
    },
    goToEdit () {
      this.$router.push(`/source/${this.source.id}/edit`)
    }
  }
}
</script>
